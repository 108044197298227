<template>
  <div class="main">
    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>
    <common-top title="新增用户"> </common-top>
    <loading-cmp v-if="showLoad"></loading-cmp>
    <div class="scrollDiv" v-show="isUserPm" style="margin-top: 10px">
      <dept-tree
        :showModal="showModal"
        @chooseDept="chooseDept"
        @cancel="showModal = false"
      ></dept-tree>
      <div class="formItem">
        <div class="formItemTitle">姓名*</div>
        <div class="detail">
          <Input
            width="200px"
            type="text"
            v-model="wxUser.name"
            placeholder="姓名"
          >
          </Input>
        </div>
      </div>

      <div class="formItem">
        <div class="formItemTitle">手机号*</div>
        <div class="detail">
          <Input
            width="200px"
            type="text"
            v-model="wxUser.mobile"
            placeholder="手机号"
          >
          </Input>
        </div>
      </div>

      <div class="formItem">
        <div class="formItemTitle">身份证号</div>
        <div class="detail">
          <Input
            width="200px"
            type="text"
            v-model="wxUser.idCard"
            placeholder="身份证号"
          >
          </Input>
        </div>
      </div>

      <div class="formItem" style="height: auto">
        <div class="formItemTitle">选择单位</div>
        <div
          class="detail"
          style="color: #808695; font-weight: bold; text-align: left"
        >
          {{ curDept.title }}
        </div>
        <div style="position: absolute; right: 20px" @click="showModal = true">
          <Button type="default">选择</Button>
        </div>
      </div>

      <div style="padding: 10px 20px" @click="addUser">
        <div class="buttonLarge">添加</div>
      </div>

      <div>
        <div style="width: 98%; margin: 0 auto; text-align: left">
          <i style="">
            <svg
              t="1646638068111"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              p-id="4385"
              width="14"
              height="14"
            >
              <path
                d="M512 0C229.215086 0 0 229.231543 0 512c0 282.784914 229.215086 512 512 512s512-229.215086 512-512C1024 229.231543 794.786743 0 512 0zM512 950.857143C269.999543 950.857143 73.142857 754.000457 73.142857 512 73.142857 270.017829 269.999543 73.142857 512 73.142857s438.857143 196.874971 438.857143 438.857143C950.857143 754.000457 754.000457 950.857143 512 950.857143z"
                p-id="4386"
                fill="#666"
              ></path>
              <path
                d="M585.142857 402.285714 475.428571 402.285714 438.857143 402.285714 402.285714 402.285714 402.285714 438.857143 438.857143 438.857143 438.857143 768 402.285714 768 402.285714 804.571429 621.714286 804.571429 621.714286 768 585.142857 768Z"
                p-id="4387"
                fill="#666"
              ></path>
              <path
                d="M512 274.285714m-73.142857 0a40 40 0 1 0 146.285714 0 40 40 0 1 0-146.285714 0Z"
                p-id="4388"
                fill="#666"
              ></path></svg
            >注意: 用户录入成功后，若该用户为新用户，需扫描企业二维码进入;
            若用户已在企业号，则将该用户部门调整到选定的单位中
            <div @click="showCode = true" style="color: red">
              点击显示企业二维码
            </div>
          </i>
        </div>
        <Drawer title="企业二维码" placement="top" v-model="showCode">
          <div
            style="
              margin: 0 auto;
              width: 100;
              display: flex;
              justify-content: center;
            "
          >
            <img style="height: 180px" src="../../assets/qrCode.png" alt="" />
          </div>
        </Drawer>
      </div>
    </div>
    <no-pass-com v-show="!isUserPm"> </no-pass-com>
  </div>
</template>

<script>
// @ is an alias to /src
import { wxConPost, getUserPm } from "../../api/httpApi";
import commonTop from "../../components/commonTop.vue";
import noPassCom from "../../components/noPassCom.vue";
import diaLogliu from "../../components/dialogLiu.vue";
import loadingCmp from "../../components/loadingCmp.vue";
import deptTree from "../../components/deptTree.vue";

export default {
  name: "contentStats",
  components: {
    commonTop,
    loadingCmp,
    noPassCom,
    deptTree,
    diaLogliu,
  },

  data() {
    return {
      isUserPm: false,
      showLoad: true, // 显示加载框

      showModal: false, // 显示部门弹框
      showCode: false,

      curDept: {},
      wxUser: {}, // 输入用户基本信息

      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项
    };
  },

  methods: {
    back() {
      if (this.showTaskList) {
        this.$router.push({ path: "/statsmain" });
      } else {
        this.showTaskList = true;
      }
    },
    chooseDept(emitData) {
      this.curDept = emitData;
      this.showModal = false;
    },
    regMobile(str) {
      return str.match(/^(0|86|17951)?1[0-9]{10}$/);
    },
    regEmail(str) {
      return str.match(
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
      );
    },
    regIdCar(str) {
      return str.match(
        /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
      );
    },
    async addUser() {
      // this.dlgShowAddMember = false;
      if (!this.curDept.id) {
        this.$Message.info("请选择部门");
        return;
      }
      if (!this.wxUser.mobile) {
        this.$Message.info("请录入手机号");
        return;
      }
      if (!this.wxUser.mobile) {
        this.$Message.info("请录入姓名");
        return;
      }
      if (!this.regMobile(this.wxUser.mobile)) {
        this.$Message.info("手机号不合法");
        return;
      }
      if (this.wxUser.idCard && !this.regIdCar(this.wxUser.idCard)) {
        this.$Message.info("身份证号不合法");
        return;
      }

      let query = {
        name: this.wxUser.name, // 姓名
        mobile: this.wxUser.mobile, // 手机号or领导邮箱
        deptIdArr: [this.curDept.id], // 所属部门id列表
      };
      if (this.wxUser.idCard) {
        query["idCard"] = this.wxUser.idCard;
      }
      try {
        this.showLoad = true;
        const result = await wxConPost(
          "/services/gttt-user-mgt/user/addBaseInfo",
          query
        );
        if (!result.err) {
          this.$Message.info("用户录入成功");
          this.wxUser = {};
          this.curDept = {};
        } else {
          this.$Notice.warning({
            title: result.err,
          });
        }
        this.showLoad = false;
      } catch (e) {
        this.$Notice.warning({
          title: "添加失败，请稍后重试",
        });
        this.showLoad = false;
      }
    },
  },
  async created() {
    const userPm = getUserPm("通讯录管理");
    const _curUserZdTxlPm = _.get(userPm, ["pmValue", "txl"], []);

    if ((userPm && !_.isEmpty(_curUserZdTxlPm)) || userPm.pmSuper) {
      this.isUserPm = true;
    } else {
      this.isUserPm = false;
    }
    this.showLoad = false;
  },
  watch: {
    "wxUser.mobile": function (val) {
      if (this.wxUser.mobile.length == 11) {
        const _regMobile = this.regMobile(this.wxUser.mobile);

        if (!_regMobile) {
          this.dailogshow = true;
          this.dailogoptions.content = "手机号不合法";
          this.dailogoptions.textColor = "white";
          setTimeout(() => {
            this.dailogshow = false;
          }, 2000);
        }
      }
    },
    "wxUser.idCard": function (val) {
      if (this.wxUser.mobile.idCard == 18) {
        const _regMobile = this.regIdCar(this.wxUser.mobile);
        if (!_regMobile) {
          this.dailogshow = true;
          this.dailogoptions.content = "身份证号不合法";
          this.dailogoptions.textColor = "white";
          setTimeout(() => {
            this.dailogshow = false;
          }, 2000);
        }
      }
    },
  },
};
</script>

<style scoped>
.formItem {
  margin-top: 3px;
  padding: 6px 10px;
  margin: 5px 0;
  box-shadow: 0 1px 6px rgb(0 0 0 / 8%);
  display: flex;
  justify-content: space-between;
  min-height: 41px;
  line-height: 41px;
  position: relative;
}
.formItem .formItemTitle {
  font-weight: bold;
  color: #2d4761;
}
.formItem .detail {
  width: 80%;
}
</style>
